import { FiniteListWithAsyncLoaderAbstract, ListProps } from "../Components";
import { useMercuryContext } from "../user-context";

export const fetchComponentTypes = async (context: any) => {
  const { data } = await context.gpClient.get(
    "/google-gp-v1/project/listFields/componentType"
  );
  return data.map((item: any) => ({
    title: item.label,
    value: item.value,
  }));
};
class ComponentType extends FiniteListWithAsyncLoaderAbstract {
  fetch() {
    return fetchComponentTypes(this.props.context);
  }
}

export default (props: ListProps) => {
  const context = useMercuryContext();
  return <ComponentType context={context} {...props} />;
};
