import { Descriptions, Tooltip } from "antd";
import TimezoneConverter from "../../../timezone-converter";
import PolyglotLogo from "../../../stories/assets/polyglot-logo.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const DocumentGeneralInformation = (props: any) => {
  const { details } = props;

  const {
    documentId,
    product,
    catToolStatus,
    sourceLanguageCode,
    creationTimestamp,
    lastUpdatedTimestamp,
    preprocessingDueTimestamp,
    translationDueTimestamp,
    translationDeliveredTimestamp,
    postprocessingDueTimestamp,
    postprocessingDelivered,
    customVendorDocumentMetadata,
  } = details;
  const taskId = documentId?.split("/")?.[1];

  const {
    lastImportedTimestamp,
    lastSharedDate,
    importedTimestamp,
    autoCompleteBlocked,
    autoCompleteBlockedReason,
  } = customVendorDocumentMetadata || {};

  return (
    <Descriptions bordered={true} column={2} size={"small"}>
      <Descriptions.Item label="Product">{product}</Descriptions.Item>
      <Descriptions.Item label="Source Language">
        {sourceLanguageCode}
      </Descriptions.Item>
      <Descriptions.Item label="Pre-Processing Due Date">
        <TimezoneConverter date={preprocessingDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Translation Due Date">
        <TimezoneConverter date={translationDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Translation Delivered">
        <TimezoneConverter date={translationDeliveredTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Post-Processing Due Date">
        <TimezoneConverter date={postprocessingDueTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Post-Processing Deliverd Date">
        <TimezoneConverter
          date={postprocessingDelivered?.deliveredTimestamppp}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Last Imported Timestamp">
        <TimezoneConverter date={lastImportedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Update Timestamp">
        <TimezoneConverter date={lastUpdatedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Status">{catToolStatus}</Descriptions.Item>
      <Descriptions.Item label="Received Date">
        <TimezoneConverter date={importedTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Last Shared Date">
        <TimezoneConverter date={lastSharedDate} />
      </Descriptions.Item>
      <Descriptions.Item label="Creation Timestamp">
        <TimezoneConverter date={creationTimestamp} />
      </Descriptions.Item>
      <Descriptions.Item label="Polyglot">
        <a
          href={`https://localization.google.com/polyglot/tasks/${taskId}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ userSelect: "none", display: "flex", alignItems: "center" }}
          title="View document in Polyglot"
        >
          {documentId}
          <img
            src={PolyglotLogo}
            alt="polyglot"
            style={{ width: "auto", height: "20px", marginLeft: "4px" }}
          />
        </a>
      </Descriptions.Item>
      <Descriptions.Item label="Auto Complete Blocked">
        {autoCompleteBlocked ? (
          <Tooltip title={autoCompleteBlockedReason}>
            <CheckOutlined style={{ color: "var(--tertiary-color)" }} />
          </Tooltip>
        ) : (
          <CloseOutlined style={{ color: "var(--red)" }} />
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default DocumentGeneralInformation;
