import { TableAbstract, CustomTooltip, TextFilter } from "../../Components";
import { useMercuryContext } from "../../user-context";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { Space, Tag } from "antd";
import LanguageCodeFilter from "../../Search/LanguageCode.filter";
import ProductFilter from "../../Search/Product.filter";
import ComponentTypeFilter from "../../Search/ComponentType.filter";
import BooleanWithoutNotSet from "../../Search/BooleanWithoutNotSet.filter";
import ProjectRoleFilter from "./ProjectRole.filter";
import ProgramRoleFilter from "./ProgramRole.filter";
import AffiliationFilter from "./Affiliation.filter";
import "./linguistManagement.scss";
import AddEditLinguist from "../../stories/LinguistManagement/AddEditLingist/AddEditLinguist";
class LinguistManagement extends TableAbstract<any> {
  defaultSortField = "requestedDate";
  FilterComponents = [
    {
      id: "sesameId",
      name: "sesameId",
      title: "Sesame Account",
      component: TextFilter,
      active: true,
    },
    {
      id: "sdl_email",
      name: "sdl_email",
      title: "RWS Email",
      component: TextFilter,
      active: true,
    },
    {
      id: "sharingEligible",
      name: "eligible",
      title: "Sharing Eligible",
      component: BooleanWithoutNotSet,
      active: true,
    },
    {
      id: "sourceLanguage",
      name: "sourceLanguage",
      title: "Source Language",
      component: LanguageCodeFilter,
      active: true,
    },
    {
      id: "targetLanguage",
      name: "language_codes",
      title: "Target Language",
      component: LanguageCodeFilter,
      active: true,
    },
    {
      id: "products",
      name: "products",
      title: "Product",
      component: ProductFilter,
      active: true,
    },
    {
      id: "components",
      name: "components",
      title: "Component Type",
      component: ComponentTypeFilter,
      active: true,
    },
    {
      id: "project_roles",
      name: "project_roles",
      title: "Project Role",
      component: ProjectRoleFilter,
      active: true,
    },
    {
      id: "program_role",
      name: "program_role",
      title: "Program Role",
      component: ProgramRoleFilter,
      active: true,
    },
    {
      id: "affiliation",
      name: "affiliation",
      title: "Affiliation",
      component: AffiliationFilter,
      active: true,
    },
    {
      id: "active",
      name: "active",
      title: "Active",
      component: BooleanWithoutNotSet,
      active: true,
    },
  ];
  columns = [
    {
      id: "name",
      title: "Name",
      dataIndex: "name",
      sorter: true,
      fixed: undefined,
      width: 150,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "personal_email",
      title: "Personal Email",
      dataIndex: "personal_email",
      sorter: true,
      fixed: undefined,
      width: 200,
      render: (text: string) => <CustomTooltip text={text} length={25} />,
    },
    {
      id: "sesameId",
      title: "Sesame Account",
      dataIndex: "sesameId",
      sorter: true,
      fixed: undefined,
      width: 220,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "sdl_email",
      title: "RWS Email",
      dataIndex: "sdl_email",
      sorter: true,
      fixed: undefined,
      width: 220,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "eligible",
      title: "Sharing Eligible",
      dataIndex: "eligible",
      sorter: true,
      fixed: undefined,
      width: 80,
      render: (text: boolean[]) => {
        const value = text && text[0];
        if (value === true) {
          return "Yes";
        } else if (value === false) {
          return "No";
        } else {
          return "-";
        }
      },
    },
    {
      id: "affiliation",
      title: "Affiliation",
      dataIndex: "affiliation",
      sorter: true,
      fixed: undefined,
      width: 90,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "share_notifications",
      title: "Notifications Enabled",
      dataIndex: "share_notifications",
      sorter: true,
      fixed: undefined,
      width: 80,
      render: (text: boolean) => {
        if (text === true) {
          return "Yes";
        } else if (text === false) {
          return "No";
        } else {
          return "-";
        }
      },
    },
    {
      id: "sourceLanguage",
      title: "Source Language",
      dataIndex: "sourceLanguage",
      sorter: true,
      fixed: undefined,
      width: 80,
      render: (text: string | string[]) => this.renderTags(text),
    },
    {
      id: "language_codes",
      title: "Target Languages",
      dataIndex: "language_codes",
      sorter: true,
      fixed: undefined,
      width: 80,
      render: (text: string | string[]) => this.renderTags(text),
    },
    {
      id: "products",
      title: "Products",
      dataIndex: "products",
      sorter: true,
      fixed: undefined,
      width: 400,
      render: (text: string | string[]) => this.renderTags(text),
    },
    {
      id: "productAreas",
      title: "Product Areas",
      dataIndex: "productAreas",
      sorter: false,
      fixed: undefined,
      width: 140,
      render: (text: string | string[]) => this.renderTags(text),
    },
    {
      id: "components",
      title: "Component Type",
      dataIndex: "components",
      sorter: true,
      fixed: undefined,
      width: 140,
      render: (text: string | string[]) => this.renderTags(text),
    },
    {
      id: "project_roles",
      title: "Project Roles",
      dataIndex: "project_roles",
      sorter: true,
      fixed: undefined,
      width: 140,
      render: (text: string | string[]) => this.renderTags(text),
    },
    {
      id: "program_role",
      title: "Program Role",
      dataIndex: "program_role",
      sorter: true,
      fixed: undefined,
      width: 100,
      render: (text: string) => <CustomTooltip text={text} length={17} />,
    },
    {
      id: "active",
      title: "Active",
      dataIndex: "active",
      sorter: true,
      fixed: undefined,
      width: 80,
      render: (text: boolean[]) => {
        if (Array.isArray(text)) {
          const value = text[text.length - 1];
          return value === true ? "Yes" : value === false ? "No" : "-";
        } else {
          return text === true ? "Yes" : text === false ? "No" : "-";
        }
      },
    }
  ];

  renderTags(text: string | string[]) {
    if (typeof text === "string") {
      return (
        <Tag
          color="var(--tertiary-color)"
          style={{ margin: "0 2px 2px 0", padding: "0 4px" }}
        >
          {text}
        </Tag>
      );
    }

    return text?.map((item, index) => (
      <Tag
        key={index}
        color="var(--tertiary-color)"
        style={{ margin: "0 2px 2px 0", padding: "0 4px" }}
      >
        {item}
      </Tag>
    ));
  }

  async handleSelection(type: any) {
    //@ts-ignore
    this.setState({ currentSelection: type });
  }

  async resetSelection() {
    //@ts-ignore
    this.setState({ currentSelection: "" });
  }

  renderActions() {
    //@ts-ignore
    // const { currentSelection } = this.state;
    // const { userDetails, flags } = this.props.context;
    // const selectedRows = this.state.selectedRows;

    return (
      <Space size="middle">
        <AddEditLinguist title="Add Vendor" />
        <AddEditLinguist
          isEdit
          title="Edit Vendor"
          disabled={this.state.selectedRows.length !== 1}
          initialValues={this.state.selectedRows?.[0]}
        />
      </Space>
    );
  }

  async fetch() {
    const { sortOrder, sortField } = this.state;

    const res = await this.userContext.gpClient.post(
      `/google-gp-v1/linguists/search`,
      {
        filter: this.filtersToQuery(),
        sort: {
          [sortField || "name"]: sortOrder === "ascend" ? 1 : -1,
        },
        pagination: {
          pageNumber: this.currentPage || 1,
          resultsPerPage: this.itemsPerPage,
        },
      }
    );

    return { data: res.data } as any;
  }

  pageTitle = "Linguist Management - Mercury © RWS";
  title = "Linguist Management";
  pageClass = "projectTable";
}

const LinguistManagementTable = () => {
  const context = useMercuryContext(),
    breaks = useBreakpoint();

  return (
    <LinguistManagement
      id="LinguistManagement"
      context={context}
      useLegacyEndpoints={false}
      breaks={breaks as any}
      useEstimatedRowHeight
    />
  );
};

export default LinguistManagementTable;
